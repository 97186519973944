<template>
  <div style="border-bottom: 1px solid #F0F0F0 !important;" class="d-flex justify-center toolbar">
    <v-card elevation="0" class="d-flex align-center">
      <v-card-text style="padding-bottom: 14px !important;" class="d-flex justify-space-between align-center pl-8 pr-6 pt-6">
        <div class="d-flex align-center">
          <v-autocomplete
              style="width: 120px; max-width: 400px;"
              :items="cities"
              item-value="id"
              item-text="nameRu"
              return-object
              v-model="$store.state.currentCity"
              append-icon="mdi-menu-down"
              dense
              hide-details
          ></v-autocomplete>
        </div>
        <div class="d-flex align-center">
<!--          <div class="pr-4 py-2 d-flex align-center" style="border-right: 1px solid #F0F1F3">-->
<!--            <div class="cursor-pointer d-flex align-center pt-2" style="position: relative;">-->
<!--              <div class="notification-block">2</div>-->
<!--              <heroicon name="notification" fill="#858D9D"/>-->
<!--            </div>-->
<!--          </div>-->
          <div class="ml-4 d-flex align-center">
            <div class="avatar">
              <div class="status-circle">
                <div class="status"></div>
              </div>
            </div>
            <div class="d-flex flex-column ml-3">
              <span class="text-14 font-weight-medium" style="color: #1D1F2C">{{ user?.firstName }}
                {{ user?.lastName }}</span>
              <span style="color:#4A4C56" class="text-12 font-weight-medium">{{ user?.role?.description }}</span>
            </div>
            <div class="ml-3">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      v-bind="attrs"
                      v-on="on"
                      large
                  >
                    mdi-menu-down
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                      @click="handleItemClick(item)"
                  >
                    <v-list-item-title>
                      <div class="d-flex align-center">
                        <heroicon class="mr-3" :name="item.icon" fill="#858D9D"/>
                        <span>{{ item.name }}</span>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";


export default {
  name: "LkToolbar",
  data() {
    return {
      menu: {},
      user: {},
      cities: [],
      items: [
        {
          name: 'Аккаунт',
          action: 'account',
          icon: 'acc'
        },
        {
          name: 'Выйти',
          action: 'logout',
          icon: 'logout'
        }
      ]
    };
  },
  watch: {
    updateUser(newVal) {
      if (newVal === true || newVal === false) {
        this.init()
      }
    }
  },
  methods: {
    handleItemClick(item) {
      if (item.action === 'logout') {
        this.logout()
      } else {
        this.$router.push('/' + item.action)
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
      this.exitDialog = false
    },
    init() {
      this.$http.get(`/users/${this.userId}`)
          .then(r => this.user = r.data)
      this.$http.get('/cities')
          .then(r => {
            this.cities = r.data.content;
            if (this.cities.length > 0) {
              this.$store.state.currentCity = this.cities[0];
            }
          })
    }
  },
  computed: {
    ...mapGetters(["windowWidth", "userId", "updateUser"]),
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.init()
    this.menu.name = this.$route.meta.menuName;
  }

};
</script>

<style lang="scss">
.toolbar {
  .v-input__slot {
    &::before {
      border: none !important;
    }

    &::after {
      border: none !important;
    }

    cursor: pointer !important;
  }

  .notification-block {
    position: absolute;
    z-index: 999;
    top: -5px;
    right: -5px;
    width: 19px;
    height: 18px;
    background: #000000;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }

  .avatar {
    border-radius: 50%;
    background: #E0E2E7;
    width: 32px;
    height: 32px;
    position: relative;
  }

  .status-circle {
    position: absolute;
    width: 14px;
    height: 14px;
    background: #FFFFFF;
    border-radius: 50%;
    bottom: 0;
    right: 0;
  }

  .status {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #22CAAD;
    border-radius: 50%;
    bottom: 1px;
    right: 0;
  }

  .mdi-menu-down::after {
    opacity: 0 !important;
  }

  .v-input__slot {
    background: none !important;
    border-radius: 8px !important;
  }
}

.v-menu__content {
  border-radius: 8px !important;
}

</style>
