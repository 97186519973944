import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import store from '../store';

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => (!record.meta.public && !record.meta.login))) {
    if (!store.getters.isLoggedIn) {
      next('/');
      return;
    }
  } else {
    if (store.getters.isLoggedIn) {
      next('/dashboard');
      return;
    }
  }
  next();
});

export default router;