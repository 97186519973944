import Vue from "vue";
import Vuetify from "vuetify/lib";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    // dark: localStorage.getItem('dark-theme') === 'false', // getItem возвращает строку,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#000000",
        secondary: "#818C99",
        accent: "#4A4C56",
        error: "#FF5252",
        red: "#FE252E",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        white: "#FFFFFF",
        grey: "#8D9091",
        blackGrey: "#777980",
        black: "#0B0B0B",
        neutral: "#1D1F2C"
      },
    }
  }
});
