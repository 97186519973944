<template>
  <v-footer height="auto" style="background: linear-gradient(180deg, #5B7BEB 0%, #0738E2 100%);margin-top: 73px">
    <v-container style="width: 1440px !important;">
      <v-row style="padding: 60px 30px !important;" class="ma-0 pa-0">
        <v-col cols="6" class="pa-0">
          <div style="width: 142px;height: 22px" class="mb-4">
            <v-img src="/static/images/logo-footer.svg"/>
          </div>
          <span class="text-18 white--text">Твой главный помощник в поиске работы</span>
        </v-col>
        <v-col cols="6" class="pa-0 d-flex justify-end">
          <div style="margin-right: 70px" class="d-flex flex-column">
            <span class="text-18 white--text">Продукт</span>
            <span class="text-16 white--text mt-5 mb-3">О продукте</span>
            <span class="text-16 white--text mb-3">Как это работает?</span>
            <span class="text-16 white--text mb-3">Тарифы</span>
            <span class="text-16 white--text">FAQ</span>
          </div>
          <div class="d-flex flex-column">
            <span class="text-18 white--text">Контакты</span>
            <span class="text-16 white--text mt-5 mb-3">Coverflow.letter@gmail.com</span>
            <span class="text-16 white--text">+996 777 77 77 77</span>
          </div>
        </v-col>
        <v-col style="margin-top: 80px;background: #FFFFFF !important;opacity: 10%" cols="12" class="pa-0">
          <v-divider/>
        </v-col>
        <v-col cols="12" class="pa-0 d-flex justify-space-between align-center">
          <div class="mt-6 d-flex">
            <span class="text-16 white--text mr-4">© 2023 Coverflow</span>
            <span class="text-16 white--text">All rights reserved</span>
          </div>
          <div class="d-flex mt-4">
            <v-img width="18" height="18" src="/static/images/inst.svg"/>
            <v-img class="mx-5" width="18" height="18" src="/static/images/lin.svg"/>
            <v-img width="18" height="18" src="/static/images/twit.svg"/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "app-fab",

  data: () => ({
    fab: false
  }),
  computed: {
    ...mapGetters(["windowWidth"])
  },
  methods: {
    sendEmail() {
      const recipient = "support@parcelpoint.us";
      const subject = "Help";
      const body = "Hello! I have some questions:\n\n";
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoLink;
    },
    onScroll() {
      if (typeof window === "undefined") return;

      const top = window.pageYOffset || document.documentElement.offsetTop || 0;

      this.fab = top > 300;
    },
    toTop() {
      this.$router.push({hash: ""});
      this.$vuetify.goTo(0);
    }
  }
};
</script>
