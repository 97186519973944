<template>
  <v-form ref="form" v-model="valid" :disabled="disabled" :readonly="readonly">
    <v-container :class="dense ? 'ma-0 pa-0' : null" fluid>
      <v-progress-linear :active="loading" indeterminate/>
      <v-row v-show="!loading || !!value.hide" :dense="dense">
        <v-col v-for="[key, value] of Object.entries(fields)" v-if="!value.hidden"
               :key="key"
               :cols="value.cols || (value.lg || value.md || value.sm || value.xl || value.xs ? undefined : 12)"
               :class="colClass"
               :lg="value.lg"
               :md="value.md"
               :sm="value.sm"
               :xl="value.xl"
               :xs="value.xs"
        >
          <slot v-if="!value.type" :name="key" v-bind="{ key: key, item: value } "></slot>
          <v-text-field
              outlined flat
              v-else-if="((value.type.name === String.name) && value.area !== true) || value.type.name === Number.name"
              :value="getter(key)"
              @input="setter(key, $event)"
              :disabled="value.disabled"
              :readonly="value.readonly"
              :suffix="value.suffix"
              :dense="dense"
              :hint="value.hint"
              :persistent-hint="value.persistentHint"
              :append-icon="value.appendIcon"
              :append-outer-icon="value.appendOuterIcon"
              :prepend-icon="value.prependIcon"
              :prepend-inner-icon="value.prependInnerIcon"
              :placeholder="value.placeholder"
              @click:append="callCallback(value.append)"
              :hide-details="value.hideDetails"
              @click:append-outer="callCallback(value.appendOuter)"
              @click:prepend="callCallback(value.prepend)"
              @click:prepend-inner="callCallback(value.prependInner)"
              :label="value.label || key"
              :rules="(value.type.name === Number.name ? [ s => !isNaN(s) || 'Числовое поле' ] : []).concat(value.rules || [])"
              :type="!!value.password ? 'password' : (value.type.name === Number.name ? 'number' : 'text')"
          >
            <template v-slot:prepend v-if="!!value.info">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <div style="width: 200px">
                  {{ value.info }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:append-outer v-if="!!value.buttonAppend">
              <v-btn small color="primary" :disabled="value.disabled" @click="clickAppendOuterButton(key)">
                {{ !!value.buttonText ? value.buttonText : 'кнопка' }}
              </v-btn>
            </template>
          </v-text-field>
          <v-textarea
              outlined
              v-else-if="(value.type.name === String.name) && value.area === true"
              :value="getter(key)"
              @input="setter(key, $event)"
              :disabled="value.disabled"
              :readonly="value.readonly"
              :dense="dense"
              :label="value.label || key"
              :rules="value.rules"
              :loading="value.loading"
              :append-icon="value.appendIcon"
              :append-outer-icon="value.appendOuterIcon"
              :prepend-icon="value.prependIcon"
              :prepend-inner-icon="value.prependInnerIcon"
              :outlined="value.outlined"
              :hide-details="value.hideDetails"
              @click:append="callCallback(value.append)"
              @click:append-outer="callCallback(value.appendOuter)"
              @click:prepend="callCallback(value.prepend)"
              @click:prepend-inner="callCallback(value.prependInner)"
          >
            <template v-slot:prepend v-if="!!value.info">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                {{ value.info }}
              </v-tooltip>
            </template>
            <template v-slot:append-outer v-if="!!value.buttonAppend">
              <v-btn small color="primary" :disabled="value.disabled" @click="clickAppendOuterButton(key)">
                {{ !!value.buttonText ? value.buttonText : 'кнопка' }}
              </v-btn>
            </template>
          </v-textarea>
          <v-autocomplete
              outlined
              v-else-if="value.type.name === Array.name"
              :value="getter(key)"
              @input="setter(key, $event)"
              @change="setter(key, $event)"
              :disabled="value.disabled"
              :readonly="value.readonly"
              :chips="value.multiple"
              :hide-details="value.hideDetails"
              :clearable="value.clearable !== false"
              :deletable-chips="value.multiple"
              :dense="dense"
              :hint="value.hint"
              :item-text="value.text"
              :item-value="value.value"
              :items="value.items"
              :label="value.label || key"
              :multiple="value.multiple"
              :persistent-hint="value.persistentHint"
              :return-object="value.returnObject === undefined || value.returnObject"
              :rules="value.rules"
              :loading="value.loading"
              :append-outer-icon="value.appendOuterIcon"
              :prepend-icon="value.prependIcon"
              :prepend-inner-icon="value.prependInnerIcon"
              @click:append-outer="callCallback(value.appendOuter)"
              @click:prepend="callCallback(value.prepend)"
              @click:prepend-inner="callCallback(value.prependInner)"
              no-data-text="no data"
              :small-chips="value.multiple"
              :search-input.sync="syncSearch[key]"
              @keyup="updSyncSearch(key)"
              @blur="updSyncSearchOnBlur(key)"
          >
            <template v-slot:prepend v-if="!!value.info">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <div style="width: 200px">
                  {{ value.info }}
                </div>
              </v-tooltip>
            </template>
            <template v-slot:append v-if="!!value.buttonAppend">
              <v-btn small color="primary" :disabled="value.disabled" @click="clickAppendOuterButton(key)">
                {{ !!value.buttonText ? value.buttonText : 'кнопка' }}
              </v-btn>
            </template>
          </v-autocomplete>
          <span class="d-flex align-center"
                v-else-if="value.type.name === Boolean.name">
            <v-btn icon class="mx-1" v-if="value.prependIcon"
                   @click="callCallback(value.prepend)">
              <v-icon size="24">{{ value.prependIcon }}</v-icon>
            </v-btn>
            <v-switch
                v-if="value.switch === true"
                inset
                :disabled="value.disabled"
                :readonly="value.readonly"
                :label="value.label || key"
                :rules="value.rules"
                :input-value="getter(key)"
                @change="setter(key, $event)"
            />
            <v-checkbox
                v-else
                :dense="dense"
                :disabled="value.disabled"
                :readonly="value.readonly"
                :label="value.label || key"
                :rules="value.rules"
                :input-value="getter(key)"
                @change="setter(key, $event)"
            />
            <v-btn icon class="mx-1" v-if="value.appendOuterIcon"
                   @click="callCallback(value.appendOuter)">
              <v-icon size="24">{{ value.appendOuterIcon }}</v-icon>
            </v-btn>
          </span>
          <v-text-field
              v-else-if="value.type.name === Date.name"
              type="date"
              persistent-placeholder
              placeholder="mm / dd / yyyy"
              pattern="^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$"
              outlined flat
              :value="getter(key)"
              @input="setter(key, $event)"
              :disabled="value.disabled"
              :readonly="value.readonly"
              :dense="dense"
              :label="value.label || key"
              :rules="!getter(key) ? [] :value.rules"
              :hide-details="!!value.hideDetails ?  value.hideDetails : false"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DatetimePicker from "./DatetimePicker.vue"

export default {
  name: "CustomForm",
  components: {
    DatetimePicker,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    colClass: {
      type: String,
      default: "d-flex align-center mb-1 px-2",
    },
    model: {
      type: Object,
    },
    fields: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    }
  },
  data: () => {
    return {
      datePikers: {},
      syncSearch: {},
      date: {}
    }
  },
  methods: {
    saveDate(key, value) {
      this.setter(key, value)
      this.datePikers[key] = false
    },
    clickAppendOuterButton(key) {
      this.$emit('appnedOuterButtonClick-' + key.replace(/\./g, ""), true)
    },
    updSyncSearch(key) {
      this.$emit('syncSearch-' + key.replace(/\./g, ""), this.syncSearch[key])
    },
    updSyncSearchOnBlur(key) {
      this.$emit('syncSearch-' + key.replace(/\./g, ""), null)
    },
    callCallback(callback) {
      callback?.()
    },
    setter(path, value) {
      const topObj = this.formModel;
      let obj = topObj;
      let subPathes = path.split('.');
      let subPath = subPathes[0]
      for (let i = 0; i < subPathes.length; i++) {
        subPath = subPathes[i];
        let subObj = obj[subPath]
        if (!subObj) {
          subObj = obj[subPath] = {}
        }
        if (i < subPathes.length - 1) {
          obj = subObj
        }
      }
      obj[subPath] = value;
      this.formModel = {...topObj}
    }
  },
  computed: {
    valid: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    formModel: {
      get() {
        return this.model || {}
      },
      set(value) {
        // this.$refs.form.validate()
        this.$emit('change', value)
      }
    },
    getter() {
      return path => {
        let obj = this.formModel
        if (!obj) return;

        for (let subPath of path.split('.')) {
          const subObj = obj[subPath]
          if (subObj === null || subObj === undefined) { // не делать !subObj
            return;
          }
          obj = subObj
        }
        return obj
      }
    },
  },
  created() {
    let fields = Object.entries(this.fields)
    for (let i = 0; i < fields.length; i++) {
      if (fields[i][1].type === Array) {
        this.syncSearch[fields[i][0]] = null
      }
    }
  }

}
</script>

<style>
.theme--light.v-input input, .theme--light.v-input textarea {
  color: #6b6b6b !important;
}
</style>