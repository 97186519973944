import axios from 'axios';

const mediaHttp = axios.create({
    baseURL:
        process.env.NODE_ENV === 'production'
            ? '/api/v1'
            : 'http://localhost:8081/api/v1',
    headers: {
        Accept: 'video/mp4',
        'Content-Type': 'application/json'
    },
    timeout: 180000
});


export default mediaHttp;
