<template>
  <v-app-bar style="border-bottom: 1px solid #F0F0F0 !important;" color="white" class="d-flex justify-center" app flat
             outlined height="74">
    <v-card style="width: 1440px" elevation="0" class="d-flex align-center">
      <v-card-text style="padding:0 30px!important;" class="d-flex justify-space-between align-center pa-0">
        <div style="width: 111px;height: 17px">
          <v-img src="/static/images/logo.svg"/>
        </div>
        <v-spacer/>
        <div class="d-flex align-center">
          <span class="text-16 mr-7 cursor-pointer black--text">О продукте</span>
          <span class="text-16 mr-7 cursor-pointer black--text">Как это работает</span>
          <span class="text-16 mr-7 cursor-pointer black--text">Тарифы</span>
          <span class="text-16 cursor-pointer mr-7 black--text"> FAQ</span>
        </div>
      </v-card-text>
    </v-card>
  </v-app-bar>
</template>
<script>
import {mapActions, mapGetters} from "vuex";


export default {
  name: "AppToolbar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["windowWidth"]),
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapActions(['logout']),
  },
  created() {
  },

};
</script>