<template>
  <div class="pl-3" style="border-left: 1px solid #F0F0F0;z-index: 999">
    <v-row>
      <v-col cols="12" class="pa-0">
        <lk-toolbar/>
      </v-col>
      <v-col style="min-height: 100vh !important;background: #F6F6F6" cols="12" class="pa-0">
        <router-view/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LkToolbar from "@/components/LkToolbar.vue";

export default {
  name: "LK",
  components: {LkToolbar}
}
</script>

<style scoped>

</style>