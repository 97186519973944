<template>
  <div id="appRoot" v-on:resize="getWidth">
    <template v-if="$route.meta.public">
      <v-app id="inspire" class="app">
        <v-main class="my-8">
          <v-row class="pa-0 page-wrapper">
            <v-col cols="12" class="pa-0 d-flex justify-center fill-height">
                <router-view/>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
    <template v-else-if="$route.meta.personal">
      <v-app id="inspire" class="app pa-0 ma-0">
        <v-main class="pa-0 ma-0">
          <v-row class="pa-0 d-flex page-wrapper">
            <v-col cols="2" class="pa-0 mr-n5">
              <app-drawer/>
            </v-col>
            <v-col cols="10" class="pa-0">
                <l-k/>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
    <template v-else-if="$route.meta.preview">
      <v-app id="inspire" class="app pa-0 ma-0">
        <v-main class="pa-0 ma-0">
          <v-row class="pa-0 d-flex page-wrapper">
            <v-col cols="12" class="pa-0">
              <router-view/>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </template>
    <!--    <template v-else-if="$route.meta.fullPage">-->
    <!--      <v-app id="inspire" class="app">-->
    <!--        <app-toolbar :drawer="drawer" @drawer="(status) => drawer = status"/>-->
    <!--        <v-main class="my-8">-->
    <!--          <v-row class="pa-0 page-wrapper">-->
    <!--            <v-col :style="windowWidth > 1051 && 'paddingLeft:16px !important'" cols="12" class="pa-0 page-wrapper">-->
    <!--              <router-view/>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--          <app-fab/>-->
    <!--        </v-main>-->
    <!--        <v-navigation-drawer style="padding-top: 100px" :width="windowWidth < 960 ? '70vw' : '50vw'"-->
    <!--                             v-if="drawer && windowWidth < 1051" class="drawer" v-model="drawer" app>-->
    <!--          <app-toolbar @drawer="(status) => drawer = status"/>-->
    <!--          <app-drawer/>-->
    <!--        </v-navigation-drawer>-->
    <!--      </v-app>-->
    <!--    </template>-->
    <template v-else-if="$route.meta.login">
      <v-app style="background: #F6F6F6" id="inspire" class="app">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="pa-0">
              <router-view/>
          </v-col>
        </v-row>
      </v-app>
    </template>

    <!--    <template v-else>-->
    <!--      <div class="client-header" style="padding-top: 20px">-->
    <!--        <v-app-bar flat style="background: #F5F5F5;" height="100">-->
    <!--          <div class="page-wrapper land-head d-flex" style="display: flex; align-items: center; width: 100vw;">-->
    <!--            <v-spacer v-if="currentRouteName === 'Signup' || currentRouteName === 'Login'"/>-->
    <!--            <div class="cursor-pointer" @click="$router.push('/')">-->
    <!--              <v-img src="/static/images/logo.svg" height="74px" contain/>-->
    <!--            </div>-->
    <!--            <v-spacer/>-->
    <!--            &lt;!&ndash;            <v-btn v-if="lang === 'ru'" @click="changeLang('en')" text>en</v-btn>&ndash;&gt;-->
    <!--            &lt;!&ndash;            <v-btn v-if="lang === 'en'" @click="changeLang('ru')" text>ru</v-btn>&ndash;&gt;-->
    <!--            <v-btn @click="$router.push('login')" text-->
    <!--                   v-if="currentRouteName !== 'Signup' && currentRouteName !== 'Login'">-->
    <!--              <span style="font-family: 'DM Sans'">{{ $t('loginText') }}</span>-->
    <!--            </v-btn>-->
    <!--          </div>-->
    <!--        </v-app-bar>-->
    <!--      </div>-->
    <!--      <v-app class="primary" style="background-color: #f5f5f5">-->
    <!--        <v-row class="pa-0">-->
    <!--          <v-col cols="12" class="pa-0 page-wrapper">-->
    <!--            <router-view/>-->
    <!--          </v-col>-->
    <!--        </v-row>-->
    <!--        <app-fab style="background: #F5F5F5" v-if="currentRouteName !== 'Signup' && currentRouteName !== 'Login'"/>-->
    <!--      </v-app>-->
    <!--    </template>-->
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppDrawer from "@/components/AppDrawer.vue";
import AppToolbar from "@/components/AppToolbar.vue";
import AppFab from "@/components/AppFab.vue";
import LkToolbar from "@/components/LkToolbar.vue";
import LK from "@/views/LK.vue";


export default {
  components: {
    LK,
    LkToolbar,
    AppDrawer,
    AppToolbar,
    AppFab
  },
  data: () => ({
    lang: '',
    width: 0,
    expanded: true,
    rightDrawer: false,
    showDrawer: false,
    snackbar: {
      show: false,
      text: "",
      color: ""
    },
    drawer: false,
    dialog: false,
    trackNum: ''
  }),
  computed: {
    ...mapGetters(['loading', 'windowWidth', 'modalWidth', 'globalErrorMessage', 'globalErrorDialog', 'errorMessages']),
    currentRouteName() {
      return this.$route.name;
    }
  },
  watch: {
    width() {
      this.setWindowWidth(this.width);
    }
  },
  methods: {
    ...mapActions(['init', 'setWindowWidth', 'setGlobalErrorMessage']),
    changeLang(lang) {
      this.lang = lang
      this.$i18n.locale = lang;
      localStorage.setItem('language', lang);
    },
    getWidth() {
      window.onresize = () => {
        this.width = document.body.clientWidth;
      };
    },
    handleDrawerVisible() {
      this.showDrawer = !this.showDrawer
    },
  },
  created() {
    this.init();
    this.getWidth();
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'shortcut icon';
    link.href = '/favicon.png';
    document.getElementsByTagName('head')[0].appendChild(link);


    this.width = document.body.clientWidth;
    // AppEvents.forEach(item => {
    //   this.$on(item.name, item.callback);
    // });
    window.getApp = this;


    this.$http.interceptors.response.use(
        response => {
          return response;
        },
        err => {
          return new Promise((resolve, reject) => {
            if (err?.response?.status === 401 || err?.response?.status === 403) {
              this.$store.dispatch("logout");
              this.$router.push("/");
            } else {
              reject(err);
            }
          });
        }
    );
  }
};
</script>


<style lang="scss" scoped>

</style>